import { ref, computed } from 'vue'
import { defineStore, storeToRefs } from 'pinia'
import layerConfigurations from '@/configuration/layer-configurations'
import layerSymbologies from '@/configuration/layer-symbologies'
import { addLayersSymbologies } from '@/commons/lib/symbologies'
import groupsConfig from '@/configuration/layer-groups'
import useThematicsStore from './thematics'


// TODO refactor
function buildLayerGroups (layersConfig, groupsConfig) {
  return groupsConfig.map(({ id, layers, thematics }) => ({
    id,
    thematics,
    layers: layersConfig.filter(l => layers.includes(l.id))
  }))
}
const useLayersStore = defineStore('layers', () => {
  const thematicsStore = useThematicsStore()
  const { currentThematic } = storeToRefs(thematicsStore)
  const layers = addLayersSymbologies(layerConfigurations, layerSymbologies)
  const layerGroups = groupsConfig.length ? buildLayerGroups(layers, groupsConfig) : null
  const activatedItems = ref(['cities', 'projects', 'zones', 'cities-details'])
  const visibleLayers = computed(() => {
    return layerGroups
      ? layerGroups
        .filter(({ id, thematics }) => activatedItems.value.includes(id) && thematics.includes(currentThematic.value.id))
        .map(lg => lg.layers).flat().map(l => l.id)
      : activatedItems.value
  })
  const areAllSelected = computed(() => {
    const items = groupsConfig.length ? layerGroups : layers
    return items.length === activatedItems.value.length
  })
  const toggleAll = () => {
    activatedItems.value = areAllSelected.value
      ? []
      : layerConfigurations.map(l => l.id)
  }
  const layerList = computed(() => (groupsConfig.length ? layerGroups : layers).map(l => l.id))
  const getLayerGroupsByThematic = thematic => {
    return layerGroups.filter(({ thematics }) => thematics.includes(thematic))
  }

  return {
    layerList, layers, layerGroups, visibleLayers, activatedItems, areAllSelected, toggleAll, getLayerGroupsByThematic
  }
})

export default useLayersStore
