<template>
  <my-modal :is-open="unAuthentified">
    <google-sign-in-card />
  </my-modal>
</template>

<script setup>
import { computed } from 'vue'
import { user, initialised, isJakartoUser } from './firebase-auth'
import MyModal from '@/commons/components/Modal.vue'
import GoogleSignInCard from '@/commons/auth/GoogleSignInCard.vue'
const unAuthentified = computed(() => initialised.value && (!user.value || !isJakartoUser.value))
</script>
