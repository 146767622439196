import { watch, onMounted } from 'vue'
import * as turf from '@turf/turf'
import { storeToRefs } from 'pinia'
import { displayPopup, formatNumber } from '@/commons/lib/popupHelper.js'
import useStore from '@/stores/city-store'

export default function useSelectCity (mapRef) {
  const cityStore = useStore()
  const { getGeom } = cityStore
  const { selected } = storeToRefs(cityStore)
  function getPopupProperties (feature) {
    return {
      'Routes totales ': formatNumber(feature.properties.total_roads_km.toFixed(0)),
      'Routes municipales ': formatNumber(feature.properties.managed_roads_km.toFixed(0)),
      'Population ': feature.properties.population ? formatNumber(feature.properties.population) : ' donnée non disponible'
    }
  }
  const popupUnits = {
    'Routes totales ': ' km',
    'Routes municipales ': ' km',
    'Population ': ''
  }
  const layerId = 'cities-details-fill'
  onMounted(() => {
    const map = mapRef.value.map
    map.on('click', layerId, e => {
      const feature = e.features[0]
      displayPopup(e.lngLat, layerId, mapRef, feature, getPopupProperties, popupUnits, 'sdrnom', 'Ville')
    })
  })
  watch(selected, async (newValue, oldValue) => {
    const selectedCityFeatures = await getGeom(newValue)
    const polygon = turf.polygon(selectedCityFeatures)
    const center = turf.centerOfMass(polygon.geometry.coordinates).geometry.coordinates
    mapRef.value.map.fire('closeAllPopups')
    displayPopup(center, layerId, mapRef, selectedCityFeatures, getPopupProperties, popupUnits, 'sdrnom', 'Ville')
    mapRef.value.map.setFeatureState(
      {
        source: 'cities-details-fill-source',
        sourceLayer: 'public.villes_poly',
        id: newValue
      },
      {
        selected: true
      }
    )
    mapRef.value.map.setFeatureState(
      {
        source: 'cities-details-fill-source',
        sourceLayer: 'public.villes_poly',
        id: oldValue
      },
      {
        selected: false
      }
    )
  })
}
