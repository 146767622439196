export default [
  {
    id: 'projects',
    thematics: ['overview-thematic', 'stream-thematic'],
    layers: [
      'projects-line',
      'projects-fill'
    ]
  },
  {
    id: 'cities',
    thematics: ['overview-thematic', 'stream-thematic'],
    layers: [
      'cities'
    ]
  },
  {
    id: 'zones',
    thematics: ['overview-thematic', 'stream-thematic'],
    layers: [
      'jakzones',
      'jakzones-special'
    ]
  },
  {
    id: 'cities-details',
    thematics: ['calculator-thematic'],
    layers: [
      'cities-details-line',
      'cities-details-fill'
    ]
  }
]
