<template>
  <gl-map
    ref="mapRef"
    :init-map-options="options"
    :init-map-position="mapConfiguration"
    :element-style="{
      width: 'calc(100vw - 60px)',
      height: '100vh',
      marginLeft: '60px',
    }"
  />
</template>
<script setup>
import { GlMap } from '@jakarto3d/jakui'
import mapConfiguration from '@/configuration/map-configuration'
import { shallowRef } from 'vue'
import useStoreToggleLayers from '@/commons/composables/useStoreToggleLayers'
import useToggleProjectFeatures from '@/composables/useToggleProjectFeatures.js'
import useFirebaseProtectedTiles from '@/commons/auth/useFirebaseProtectedTiles'
import useFitMapToFeatures from '@/composables/useFitMapToFeatures.js'
import useFitMapToCity from '@/composables/useFitMapToCity.js'
import useSelectProject from '@/composables/useSelectProject'
import useSelectCity from '@/composables/useSelectCity'
const transformRequest = useFirebaseProtectedTiles()
const mapRef = shallowRef(null)
useToggleProjectFeatures(mapRef)
useStoreToggleLayers(mapRef)
useFitMapToFeatures(mapRef, '.left-panel')
useFitMapToCity(mapRef, '.left-panel')
useSelectProject(mapRef)
useSelectCity(mapRef)

const options = { hash: process.env.NODE_ENV === 'development', transformRequest }
</script>
