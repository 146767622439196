<template>
  <h3 class="mapboxgl-popup__title-label">{{ popupCategory }}</h3>
  <h2 class="mapboxgl-popup__title">{{ popupTitle }}</h2>
  <template v-for="(value, key, i) in popupProperties" :key="i">
    <a
      v-if="key === 'jakartown_url'"
      class="mapboxgl-popup__button"
      :href="value"
      target="_blank"
    >
      Jakartowns<i class="fas fa-external-link-alt"></i
    ></a>
    <p v-else class="mapboxgl-popup__item">
      <span class="mapboxgl-popup__item--label">{{ key }}: </span>
      <span class="mapboxgl-popup__item--value">{{ value }}{{ getUnit(key) }}</span>
    </p>
  </template>
</template>
<script setup>
</script>
<style lang="scss">
.mapboxgl-popup, .maplibregl-popup{
  width: 200px;
  min-width: max-content;
  &-content{
    padding: 20px;
    border-radius: 6px;
    box-shadow: 0px 0px 17px -4px rgba(0,0,0,0.6);
    & > div{
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: auto;
    }
  }
  &__title{
    margin-top: 0;
  }
  &__title-label{
    margin: 0;
    color: #7e8b8b;
    font-size: 8px;
    font-weight: 600;
    text-transform: uppercase;
  }
  &__item{
    margin: 0;
    margin-bottom: 8px;
    min-width: 200px;
    &--label{
      font-weight: bold;
    }
  }
  &__button{
    grid-row: 20;
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-width: 100px;
    max-width: 180px;
    margin-top: 14px;
    padding: 6px 12px;
    color: #696969;
    background-color: #F1F1F1;
    font-size: 12px;
    border: 1px solid #696969;
    border-radius: 4px;
    text-decoration: none;
    cursor: pointer;
  }
}
</style>
