import { onMounted, watch } from 'vue'
export default useToggleLayers
/**
 * @typedef {Object} LayerConfig
 * @property {String} id - the layer id to be used for future reference
 * @property {Object} source - a mapbox source options object
 * @property {Object} style - an object with type, paint and layout mapbox style properties
 * @property {string} before - The ID of an existing layer to insert the new layer before
 */

/**
 * reactively show and hide layers
 * @param  {Object} mapRef a gl-map component vue ref
 * @param  {Array.<LayerConfig>} layersConfig an array of layer configuration objects
 * @param  {Array.<String>} visibleLayersRef a ref to an array of currently visible layer ids (from layers[].id )
 * @alias module:map-composable
 */
function useToggleLayers (mapRef, layersConfig, visibleLayersRef) {
  onMounted(() => {
    const map = mapRef.value.map
    map.on('style.load', () => {
      const symbolLayers = layersConfig.filter(l => l.style.type === 'symbol')
      symbolLayers.forEach(layer => {
        map.loadImage(`/${layer.id}.png`, (error, image) => {
          if (error) throw error
          map.addImage(`${layer.id}`, image)
        })
      })
      layersConfig.forEach(({ id, source, style, before }) => {
        const sourceId = `${id}-source`
        if (source.tiles && !source.tiles[0].startsWith('http')) {
          const pgTileservUrl = import.meta.env.VITE_PG_TILESERV_URL.startsWith('http')
            ? import.meta.env.VITE_PG_TILESERV_URL
            : `${location.origin}${import.meta.env.VITE_PG_TILESERV_URL}`
          source.tiles[0] = `${pgTileservUrl}/${source.tiles[0]}`
        }
        const layerId = id
        map.addSource(sourceId, source)
        map.addLayer(
          {
            ...style,
            id: layerId,
            source: sourceId,
            layout: {
              ...style.layout,
              visibility: visibleLayersRef.value.includes(id)
                ? 'visible'
                : 'none'
            }
          },
          before
        )
      })
    })
  })
  watch(visibleLayersRef, (newVal, oldVal) => {
    const toShow = newVal.filter(l => !oldVal.includes(l))
    const toHide = oldVal.filter(l => !newVal.includes(l))
    toShow.forEach(l => {
      mapRef.value.map.setLayoutProperty(l, 'visibility', 'visible')
    })
    toHide.forEach(l => {
      mapRef.value.map.setLayoutProperty(l, 'visibility', 'none')
    })
  })
  return {
    mapRef
  }
}
