import { ref, onMounted } from 'vue'
import { getToken } from '@/commons/auth/firebase-auth'
export default function () {
  const token = ref(null)
  onMounted(async () => {
    token.value = await getToken()
  })
  const transformRequest = (url, resourceType) => {
    if (!import.meta.env.VITE_PG_TILESERV_URL || import.meta.env.VITE_AUTH_ENABLED === 'false') return
    if (
      resourceType === 'Tile' &&
          url.includes(import.meta.env.VITE_PG_TILESERV_URL)
    ) {
      return {
        url,
        headers: { Authorization: `Bearer ${token.value}` }
      }
    }
  }
  return transformRequest
}
