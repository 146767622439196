<template>
  <div class="city-selector" v-if="isReady">
    <v-select
      v-model="selected"
      :reduce="city => city.sdridu"
      :options="options"
      label="label"
      placeholder="Choisir une ville"
    />
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import vSelect from 'vue-select'
import { storeToRefs } from 'pinia'
import 'vue-select/dist/vue-select.css'
// import geojson from '@/test-data.json'
import useStore from '@/stores/city-store'
const cityStore = useStore()
const options = ref([])
const isReady = ref(false)

const { populateData } = cityStore
const { data, selected } = storeToRefs(cityStore)

onMounted(
  async () => {
    await populateData()
    options.value = data.value.map(
      ({ sdridu, sdrnom, province }) => ({
        label: `${sdrnom}, ${province}`,
        sdridu
      })
    )
    isReady.value = true
  }
)

// watch(selected, newVal => {
//   if (newVal) {
//     setGeom(newVal.sdridu)
//   }
// })

</script>
<style lang="scss" scoped>
.city-selector {
  width: 100%;
  height: 600px;
}
</style>
