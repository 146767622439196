import {computed, ref} from 'vue'
import { defineStore } from 'pinia'
import thematics from '@/configuration/thematic-configuration'

const useThematicsStore = defineStore('thematics', () => {
  const currentThematic = ref(thematics[0])
  const tabComponent = computed(() => thematics.find(t => t.id === currentThematic.value.id).component)
  return { thematics, currentThematic, tabComponent }
})

export default useThematicsStore
