import { watch } from 'vue'
import { storeToRefs } from 'pinia'
import useCityStore from '@/stores/city-store'
import bbox from '@turf/bbox'
export default function (mapRef, leftPanelSelector) {
  const cityStore = useCityStore()
  const { getGeom } = cityStore
  const { selected } = storeToRefs(cityStore)
  watch(selected, async newValue => {
    if (!newValue) return
    const selectedFeature = await getGeom(newValue)
    const leftPanelWidth = leftPanelSelector ? document.querySelector('.left-panel').offsetWidth : 0
    const defaultPadding = 200
    const leftPadding = defaultPadding + leftPanelWidth
    const padding = { left: leftPadding, top: defaultPadding, right: defaultPadding, bottom: defaultPadding }
    mapRef.value.map.fitBounds(bbox(selectedFeature), { padding })
  })
}
