import firebase from 'firebase/app'
import 'firebase/auth'
import { ref, computed } from 'vue'
import firebaseConfig from '@/configuration/firebase'

export const user = ref(null)
export const initialised = ref(false)
if (import.meta.env.VITE_AUTH_ENABLED === 'true' && firebaseConfig) {
  firebase.initializeApp(firebaseConfig)
} else {
  if (import.meta.env.VITE_AUTH_ENABLED === 'true') { alert('missing firebase configuration in @/src/config.js') }
  user.value = false
}
const auth = import.meta.env.VITE_AUTH_ENABLED === 'true' && firebaseConfig?.apiKey ? firebase.auth() : null

if (auth) {
  auth.onAuthStateChanged(u => {
    user.value = u
    initialised.value = true
  })
}

export async function logout () {
  await auth.signOut()
}

export async function googleSignIn () {
  await auth.signInWithRedirect(new firebase.auth.GoogleAuthProvider())
}

export function useSignup () {
  const email = ref('')
  const password = ref('')

  async function signup () {
    if (email.value === '' || password.value === '') return

    const creds = await auth.createUserWithEmailAndPassword(
      email.value,
      password.value
    )

    if (!creds.user) throw Error('Signup failed')

    user.value = creds.user
  }

  return {
    email,
    password,
    signup
  }
}

export function useLogin () {
  const email = ref('')
  const password = ref('')

  async function login () {
    const resp = await auth.signInWithEmailAndPassword(
      email.value,
      password.value
    )

    if (!resp.user) throw Error('No user')

    user.value = resp.user
  }

  return {
    email,
    password,
    login
  }
}

export async function getToken () {
  return user.value ? await user.value.getIdToken() : null
}

export const isJakartoUser = computed(() => {
  return user.value && user.value?.email?.endsWith('@jakarto.com')
})
