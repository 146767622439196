<template>
  <thematic-container :thematic="currentThematic" :key="currentThematic.id">
    <template #title="thematic">
      {{ thematic.title }}
    </template>
    <template #description>
    </template>
    <template #content="thematic">
      <component :is="thematic.component"/>
    </template>
  </thematic-container>
</template>

<script>
import AnalysisComponent from '@/components/left-panel/analysis-component.vue'
import OverviewComponent from '@/components/left-panel/overview-component.vue'
import StreamComponent from '@/components/left-panel/stream-component.vue'
import useThematicsStore from '@/stores/thematics'
import { ThematicContainer } from '@jakarto3d/jakui'
import { storeToRefs } from 'pinia'

export default {
  components: {
    AnalysisComponent,
    OverviewComponent,
    StreamComponent,
    ThematicContainer
  },
  props: {
    tab: Object
  },
  setup () {
    const thematicsStore = useThematicsStore()
    const { thematics } = thematicsStore
    const { currentThematic } = storeToRefs(thematicsStore)

    return {
      thematics,
      currentThematic
    }
  }
}

</script>

<style lang="scss">
</style>
