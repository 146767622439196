import symbologyBuilder from './symbologyBuilder'
export function addLayersSymbologies (layers, symbologies) {
  return layers.map(layer => {
    const symbology = symbologies.find(s => s.id === layer.id)
    if (symbology) {
      const symbologyPaint = symbologyBuilder(symbology)
      return {
        ...layer,
        style: {
          ...layer.style,
          paint: { ...layer.style.paint, ...symbologyPaint }
        }
      }
    } else {
      return layer
    }
  })
}
export function getCategoryWidgetItems (layerId, symbologies, features) {
  const symbology = symbologies.find(s => s.id === layerId)
  const reducers = {
    categories: (acc, curr) => {
      const value = curr.properties[symbology.property]
      const item = acc.find(accItem => accItem.id === value)
      if (!item) {
        const symbologyItem = symbology.symbologyItems.find(
          i => i.value === value
        )
        if (!symbologyItem) {
          const others = acc.find(({ id }) => id === 'others-value')
          if (others) {
            others.value++
            return acc
          }
        }
        const { value: id, label: name, color } = symbologyItem
        return [...acc, { id, name, color, value: 1 }]
      } else {
        item.value++
        return acc
      }
    },
    ranges: (acc, curr) => {
      const value = curr.properties[symbology.property]
      const range = symbology.symbologyItems.find(
        i => i.min <= value && i.max > value
      )
      if (!range) {
        return acc
      }
      const rangeItem = acc.find(i => i.id === range.value)
      rangeItem.value++
      return acc
    }
  }
  if (symbology && reducers[symbology.symbologyType]) {
    const symbologyWidgetItems = symbology.symbologyItems.map(
      ({ color, label: name, value }) => ({
        id: value,
        name,
        color,
        value: 0
      })
    )
    const othersWidgetItems = {
      id: 'others-value',
      name: 'Others',
      color: symbology.defaultColor,
      value: 0
    }
    const initialWidgetItems =
      symbology.symbologyType === 'categories'
        ? [...symbologyWidgetItems, othersWidgetItems]
        : symbologyWidgetItems
    return features.reduce(
      reducers[symbology.symbologyType],
      initialWidgetItems
    )
  } else return []
}

export function createLayerFilter (filter, layerId, symbologies) {
  const filterBuilder = {
    categories: filter => {
      if (filter === 'others-value') {
        const symbologyValues = symbology.symbologyItems.map(s => s.value)
        return [
          '!',
          [
            'any',
            ['in', ['get', symbology.property], ['literal', symbologyValues]]
          ]
        ]
      }
      return filter
        ? ['any', ['in', ['get', symbology.property], ['literal', filter]]]
        : ['all']
    },
    ranges: filter => {
      const rangeItem = symbology.symbologyItems.find(i => i.value === filter)
      return filter
        ? [
            'all',
            [
              '>=',
              ['to-number', ['get', symbology.property]],
              parseFloat(rangeItem.min)
            ],
            [
              '<',
              ['to-number', ['get', symbology.property]],
              parseFloat(rangeItem.max)
            ]
          ]
        : ['all']
    }
  }
  const symbology = symbologies.find(s => s.id === layerId)
  if (!symbology) return false
  const filterFunction = filterBuilder[symbology.symbologyType]
  if (!filterFunction) return false
  return [{ layerId, filter: filterFunction(filter) }]
}
