import { watch } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import { user, initialised } from '@/commons/auth/firebase-auth'
const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomeView,
    meta: { public: !import.meta.env.VITE_AUTH_ENABLED }
  }
]

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes
})

if (user.value !== false) {
  router.beforeEach((to, _, next) => {
    if (initialised.value) {
      next()
    } else {
      watch(
        () => initialised.value,
        newVal => {
          if (newVal) {
            next()
          }
        }
      )
    }
  })
}

export default router
