import { ref } from 'vue'
import { defineStore } from 'pinia'
import { getDataFromFunction } from '@/commons/lib/apiClient.js'

async function getData () {
  const data = await getDataFromFunction('get_cities_details')
  return data
}

async function getGeom (sdridu) {
  const data = await getDataFromFunction('get_city_geom', { sdridu })
  return data
}

export default defineStore('city', () => {
  const data = ref([])
  const selected = ref(null)
  const populateData = async () => {
    data.value = await getData()
  }
  return {
    data,
    populateData,
    selected,
    getGeom
  }
})
