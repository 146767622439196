<template>
  <div class="overview-component">
  <expandable-toggle-list v-model:expanded="expandedItems" :items="items" :toggleable="false">
    <template #left="toggleListItem">
      <div class="toggleListItem__header">
        <h2 class="toggle-title">{{ toggleListItem.title }}</h2>
        <button @click="areAllSelected ? unselectAllProjects() : selectAllProjects()" v-if="toggleListItem.id === 'projects'">
          {{ areAllSelected ? 'Tout masquer' : 'Tout afficher' }}
        </button>
      </div>
    </template>
    <template #content="toggleListItem">
        <check-list v-if="toggleListItem.id === 'layers'" v-model="activeLayers" :items="toggleListItem.layers" >
          <template #left="checkListItem">
            <span class="item-label">{{ $t(`layers-label.${checkListItem.id}`) }}</span>
          </template>
        </check-list>
        <check-list v-if="toggleListItem.id === 'projects'" v-model="visibleProjects" :items="toggleListItem.layers">
          <template #left="checkListItem">
            <span class="item-label">{{ checkListItem.label }}</span>
          </template>
          <template #right="checkListItem">
            <div class="search-icon-container">
              <search-icon  @click="selectedProject = checkListItem.id" class="projects-search-icon" :color="iconColor"/>
            </div>
          </template>
        </check-list>
      </template>
  </expandable-toggle-list>
</div>
</template>
<script setup>
import { ref, watch } from 'vue'
import { ExpandableToggleList, CheckList, SearchIcon } from '@jakarto3d/jakui'
import { storeToRefs } from 'pinia'
import useOverviewStore from '@/stores/overview'
import useLayersStore from '@/stores/layer-store'
import useProjectStore from '@/stores/project-store'

const projectStore = useProjectStore()
const { projectsLayers, visibleProjects, selectedProject, areAllSelected } = storeToRefs(projectStore)
const { unselectAllProjects, selectAllProjects } = projectStore

const layersStore = useLayersStore()
const { activatedItems: activeLayers } = storeToRefs(layersStore)
const { getLayerGroupsByThematic } = layersStore
const overviewStore = useOverviewStore()
const { expandedItems } = storeToRefs(overviewStore)

const layersConfig = { id: 'layers', title: 'Couches', layers: getLayerGroupsByThematic('overview-thematic') }
const projectsConfig = { id: 'projects', title: 'Projets', layers: projectsLayers }

const iconColor = getComputedStyle(document.documentElement).getPropertyValue('--primary')
const items = ref([layersConfig, projectsConfig])
watch(activeLayers, (newValue) => {
  items.value = newValue.includes('projects') ? [layersConfig, projectsConfig] : [layersConfig]
})

</script>
<style  lang="scss" scoped>

  .projects-search-icon {
    height: 16px;
    cursor: pointer;
  }
  .item-label {
    padding-left: 24px;
  }
  .toggle-title {
    font-size: 17px;
    font-weight: 500;
  }
  .overview-component {
    :deep(.toggle-list__header__left) {
      align-items: center;
    }

    :deep(.expandable-toggle-list__content--expanded) {
      max-height: initial;
    }
  }
  .toggleListItem__header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }
</style>
