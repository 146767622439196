import { watch } from 'vue'
import { storeToRefs } from 'pinia'
import useProjectStore from '@/stores/project-store'
import bbox from '@turf/bbox'
export default function (mapRef, leftPanelSelector) {
  const projectStore = useProjectStore()
  const { selectedProject, features } = storeToRefs(projectStore)
  watch(selectedProject, newValue => {
    if (!newValue) return
    const selectedFeature = features.value.find(f => f.id === newValue)
    const leftPanelWidth = leftPanelSelector ? document.querySelector('.left-panel').offsetWidth : 0
    const defaultPadding = 400
    const leftPadding = defaultPadding + leftPanelWidth
    const padding = { left: leftPadding, top: defaultPadding, right: defaultPadding, bottom: defaultPadding }
    mapRef.value.map.fitBounds(bbox(selectedFeature), { padding })
  })
}
