import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { createI18n } from 'vue-i18n'
import FloatingVue from 'floating-vue'

import App from './App.vue'
import router from './router'

import messages from './messages'
import 'normalize.css'
import './assets/main.css'
import 'floating-vue/dist/style.css'

const i18n = createI18n({
  allowComposition: true,
  legacy: false,
  locale: 'fr',
  fallbackLocale: 'en',
  messages
})

const app = createApp(App)

app.use(createPinia())
app.use(router)
app.use(i18n)
app.use(FloatingVue)
app.mount('#app')
