import { watch, onMounted } from 'vue'
import { storeToRefs } from 'pinia'
import useProjectStore from '@/stores/project-store'
export default function (mapRef) {
  const projectStore = useProjectStore()
  const { visibleFeatures } = storeToRefs(projectStore)
  onMounted(() => {
    mapRef.value.map.on('load', () => {
      updateMapLayers(mapRef, visibleFeatures.value)
    })
  })
  watch(visibleFeatures, newValue => {
    updateMapLayers(mapRef, newValue)
  })
}
function updateMapLayers (mapRef, newValue) {
  ['projects-line-source', 'projects-fill-source'].forEach(sourceId => {
    const source = mapRef.value.map.getSource(sourceId)
    const newSourceData = {
      type: 'FeatureCollection',
      features: newValue
    }
    if (source) source.setData(newSourceData)
  })
}
