import { watch, onMounted } from 'vue'
import { storeToRefs } from 'pinia'
import useProjectStore from '@/stores/project-store'
import { displayPopup, formatNumber } from '@/commons/lib/popupHelper.js'
import * as turf from '@turf/turf'

export default function useSelectProject (mapRef) {
  function getPopupProperties (feature) {
    const statusTl = {
      current: 'Scan En cours',
      validation: 'En cours de validation',
      done: 'Scan Terminé',
      pending: 'Scan non commencé'
    }
    return {
      'État ': statusTl[feature.properties.status],
      'Km linéaire ': formatNumber(feature.properties.rounded_road_km),
      'Trajectoires validées ': feature.properties.perc_validated_traj
    }
  }
  const popupUnits = {
    'Km linéaire ': ' km',
    'Trajectoires validées ': ' %'
  }
  const layerId = 'projects-fill'
  const projectStore = useProjectStore()
  const { selectedProject, features } = storeToRefs(projectStore)
  onMounted(() => {
    const map = mapRef.value.map
    map.on('click', layerId, e => {
      const feature = e.features[0]
      displayPopup(e.lngLat, layerId, mapRef, feature, getPopupProperties, popupUnits, 'project_name', 'Projet')
    })
  })
  watch(selectedProject, newValue => {
    const selectedProjectFeature = features.value.find(f => f.id === newValue)
    const polygon = turf.polygon(selectedProjectFeature)
    const center = turf.centerOfMass(polygon.geometry.coordinates).geometry.coordinates
    mapRef.value.map.fire('closeAllPopups')
    displayPopup(center, layerId, mapRef, selectedProjectFeature, getPopupProperties, popupUnits, 'project_name', 'Projet')
    features.value.forEach(f => {
      const selected = selectedProjectFeature.properties.project_name === f.properties.project_name
      mapRef.value.map.setFeatureState(
        {
          source: 'projects-fill-source',
          id: f.properties.project_name
        },
        {
          selected
        }
      )
    })
  })
}
