import { defineComponent, createApp, nextTick } from 'vue'
import maplibregl from 'maplibre-gl'
import popup from '@/commons/components/popup.vue'

function buildPopup (id, feature, getPopupProperties, popupUnits, titleProp, categoryLabel) {
  return defineComponent({
    extends: popup,
    setup () {
      return {
        popupTitle: feature.properties[titleProp],
        popupCategory: categoryLabel,
        popupProperties: getPopupProperties(feature),
        popupUnits
      }
    },
    methods: {
      getUnit (label) {
        return this.popupUnits[label]
      }
    }
  })
}

export function displayPopup (lngLat, layerId, mapRef, feature, getPopupProperties, popupUnits, titleProp, categoryLabel) {
  const popup = new maplibregl.Popup()
    .setLngLat(lngLat)
    .setHTML(`<div id="popup-content-${layerId}"></div>`)
    .addTo(mapRef.value.map)
  const Popup = buildPopup(layerId, feature, getPopupProperties, popupUnits, titleProp, categoryLabel)
  nextTick(() => {
    createApp(Popup).mount(`#popup-content-${layerId}`)
  })
  mapRef.value.map.on('closeAllPopups', () => {
    popup.remove()
  })
}

export function formatNumber (number, locale = 'fr-FR') {
  return new Intl.NumberFormat(locale).format(number)
}
