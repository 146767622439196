<template>
  <div v-if="projectsByCity" class="container" style="max-width: 75vw;">
    <div class="row header">
      <div class="cell city void"></div>
      <div v-for="(month, index) in months" :key="index" class="cell month">
        <span class="label" style="display: block;">{{ month }}</span>
      </div>
    </div>

      <!-- Data rows for cities -->
    <div class="data-rows">
      <div v-for="(projects, key) in projectsByCity" :key="key" class="row">
        <div class="cells">
          <div class="cell city">
            <div class="city__label" @click="selectProjectByName(projects[0].project_name)"> {{ projects[0].project_name }}  </div>
          </div>
          <div v-for="monthIndex in months.length" :key="monthIndex" class="cell content">
            <div v-for="i in getMonthSubcells(monthIndex)" :key="`${monthIndex}-${i}`" class="subcell" :style="{ width: buildSubcellWidth(monthIndex)}" :class="{ last: i === getDaysInMonth(monthIndex, 2024)-1 }"/>
          </div>
        </div>
        <div class="box constraint" :style="{ width: getWidthConstraint(key), marginLeft: getMarginLeftConstraint(key) }"  v-tooltip="`${getStartScan(key)} <--> ${getEndScan(key)}`"/>
        <div class="today-line" :style="{ marginLeft: getMarginLeft()}"></div>
        <div
          v-for="project in projects"
          @click="selectProjectByName(projects[0].project_name)"
          :key="project.id"
          class="box"
          :class="[project.truck_id]"
          :style="{ width: getWidth(project), marginLeft: getMarginLeft(project.start) }"
          v-tooltip="{ content: `<b>${project.start}</b> - <b>${project.end}</b>`, html: true }"
        />
      </div>
    </div>
    <div  v-if="updatedAt" style="text-align: right; margin-top: 8px;">dernière mise à jour : {{ updatedAt }}</div>
</div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import useProjectStore from '@/stores/project-store'
import _ from 'lodash'
import { getDataFromFunction } from '@/commons/lib/apiClient.js'
const { features, selectProjectByName } = useProjectStore()

const projectsByCity = ref(null)
const months = ref([
  'Jan', 'Fev', 'Mar', 'Avr', 'Mai', 'Jun',
  'Jul', 'Aou', 'Sep', 'Oct', 'Nov', 'Dec'
])

const graphWidthExpression = '100% - 252px'
const currentYear = ref('2024-01-01')
const updatedAt = ref(null)

function getDaysInMonth (monthIndex, year) {
  const month = monthIndex + 1
  const lastDay = new Date(year, month, 0).getDate()
  return lastDay
}

function getMonthSubcells (monthIndex) {
  const days = getDaysInMonth(monthIndex, 2024)
  return [...Array(days).keys()]
}
function buildSubcellWidth (monthIndex) {
  const width = 100 / getDaysInMonth(monthIndex, 2024)
  return `${width.toString()}%`
}
onMounted(async () => {
  const projects = await getDataFromFunction('get_planification_timeline_json_result')
  projectsByCity.value = _.groupBy(projects, item => item.project_name)
  updatedAt.value = _.maxBy(projects, 'updated_at').updated_at
})

function getDurationInSeconds (dateStart, dateEnd) {
  const dateEndDate = dateEnd ? new Date(dateEnd) : new Date()
  const dateStartDate = dateStart ? new Date(dateStart) : new Date()
  const duration = Math.abs((dateEndDate - dateStartDate)) / 1000
  return duration
}
const yearInSeconds = 60 * 60 * 24 * 365

const itemStart = start => {
  const elapsedTime = getDurationInSeconds(currentYear.value, start)
  const position = elapsedTime / yearInSeconds
  return position
}

const itemLength = item => {
  const lengthTime = getDurationInSeconds(item.start, item.end)
  const lengthPart = lengthTime / yearInSeconds
  return lengthPart
}

const getWidth = item => {
  return `calc(${itemLength(item)} * (${graphWidthExpression}))`
}
const getMarginLeft = startDate => {
  const itemStartPosition = itemStart(startDate)
  return `calc(${itemStartPosition} * (${graphWidthExpression}))`
}

function getStartScan (projectName) {
  return features.find(f => f.properties.project_name === projectName).properties.start_scan ?? currentYear.value
}

function getEndScan (projectName) {
  return features.find(f => f.properties.project_name === projectName).properties.end_scan ?? currentYear.value.substring(0, 4).concat('-12-01')
}

function getWidthConstraint (projectName) {
  const startCaptation = getStartScan(projectName)
  const endCaptation = getEndScan(projectName)
  const myItem = { start: startCaptation, end: endCaptation }
  return getWidth(myItem)
}

function getMarginLeftConstraint (projectName) {
  const startCaptation = getStartScan(projectName)
  return getMarginLeft(startCaptation)
}

</script>

<style lang="scss" scoped>
.box {
    position: absolute;
    top: 0px;
    left: 252px;
    width: 40px;
    border-radius: 1px;
    height: 10px;
    cursor: pointer;
    &.jak1 {
      top: 4px;
      background-color: #325F91;
    }
    &.jak2 {
      top: 15px;
      background-color: #6E3787;
    }
    &.constraint {
      top: 5px;
      height: 20px;
      background-color: #f39dcc;
      opacity: 0.25;
    }
  }
.container {
  display: flex;
  flex-direction: column;
  font-weight: 400;
  margin: 12px;
  .today-line {
    position: absolute;
    top: 0px;
    left: 252px;
    width: 2px;
    height: 100%;
    background: repeating-linear-gradient(
      to bottom,
      grey,
      grey 5px,
      white 5px,
      white 10px
    );
  }
  .row {
    position: relative;
    height: 30px;
  }
  .row.header, .cells {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    .cell {
      display: flex;
      // flex-direction: column;
      flex: 1;
      height: 30px;
      text-align: center;
      align-items: center;
      position: relative;
      justify-content: center;
        &.city {
          min-width: 250px;
          font-size: 14px;
          background-color: white;
          border: 1px solid lightgray;
          &.void {
            border: none;
            background-color: transparent;
          }
          i {
            position: absolute;
            top: 6px;
            right: 6px;
            color:lightgray;
          }
          .city__label {
            cursor: pointer;
          }
        }
        &.content {
          background-color: white;
          border: 1px solid lightgray;
        }
        &.month {
          border: 1px lightgray solid;
          background-color: white;
          border: 1px solid lightgray;
          border-right-style: dashed;
          border-left-style: dashed;
          text-transform: uppercase;
        }
        .subcell {
          border-right: 1px solid lightgray;
          height: 100%;
          opacity: 70%;
          &.last {
            border-right: 1px solid purple;
          }
        }
    }
  }
}
</style>
