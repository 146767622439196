export default [
  {
    id: 'cities',
    source: {
      type: 'vector',
      tiles: ['public.villes_poly/{z}/{x}/{y}.pbf?']
    },
    style: {
      type: 'line',
      'source-layer': 'public.villes_poly',
      paint: {
        'line-color': 'grey',
        'line-opacity': 0.4
      }
    },
    after: 'road_minor_case'
  },
  {
    id: 'cities-details-line',
    source: {
      type: 'vector',
      tiles: ['public.villes_poly/{z}/{x}/{y}.pbf?filter=managed_roads_km IS NOT NULL']
    },
    style: {
      type: 'line',
      'source-layer': 'public.villes_poly',
      paint: {
        'line-color': 'grey',
        'line-opacity': 0.4
      }
    },
    after: 'road_minor_case'
  },
  {
    id: 'cities-details-fill',
    source: {
      type: 'vector',
      tiles: ['public.villes_poly/{z}/{x}/{y}.pbf?filter=managed_roads_km IS NOT NULL'],
      promoteId: 'sdridu'
    },
    style: {
      type: 'fill',
      'source-layer': 'public.villes_poly',
      paint: {
        'fill-color': 'grey',
        'fill-opacity': [
          'case',
          ['boolean', ['feature-state', 'selected'], false], 0.5, 0.1
        ]
      }
    },
    after: 'road_minor_case'
  },
  {
    id: 'jakzones',
    source: {
      type: 'vector',
      tiles: ['public.jakzones/{z}/{x}/{y}.pbf?filter=(affich_carte=\'1\')']
    },
    style: {
      type: 'line',
      'source-layer': 'public.jakzones',
      paint: {
        'line-color': '#789bba',
        'line-width': 4
      }
    },
    after: 'road_minor_case'
  },
  {
    id: 'jakzones-special',
    source: {
      type: 'vector',
      tiles: ['public.jakzones_special/{z}/{x}/{y}.pbf?filter=(affich_carte=\'1\')']
    },
    style: {
      type: 'line',
      'source-layer': 'public.jakzones_special',
      paint: {
        'line-color': '#789bba',
        'line-width': 4
      }
    },
    after: 'road_minor_case'
  },
  {
    id: 'projects-line',
    source: {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: []
      }
    },
    style: {
      type: 'line',
      paint: {
        'line-color': '#e9e29c',
        'line-width': 4
      }
    },
    after: 'road_minor_case'
  },
  {
    id: 'projects-fill',
    source: {
      type: 'geojson',
      promoteId: 'project_name',
      data: {
        type: 'FeatureCollection',
        features: []
      }
    },
    style: {
      type: 'fill',
      paint: {
        'fill-color': '#e9e29c',
        'fill-opacity': [
          'case',
          ['boolean', ['feature-state', 'selected'], false], 0.5, 0.1
        ]
      }
    },
    after: 'road_minor_case'
  }
]
